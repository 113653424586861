import './App.css';
import React from 'react';
import { Component, useState, useEffect } from 'react';


class CookieConsentElement_ extends Component {
 
  constructor(props) {
    super(props)
    this.state = {
      cookieShow: true
    }
    this.handleClick = this.handleClick.bind(this)
    this.showCookiePopup = this.showCookiePopup.bind(this)
    this.removeCookiePopup = this.removeCookiePopup.bind(this)
  }

  handleClick() {
    this.setState(state => ({
      cookieShow: false
    }));
  {/*localStorage.setItem('action30perc','true')*/}
  
  }


  showCookiePopup() {
    return (
    <div className="d-flex justify-content-center container mt-5 overlay2"  style={{ zIndex:999, pointerEvents:'none'}}>
        <div className="row " >
		<div className="col-md-3" >
                </div>
            <div className="col-md-12 "  >
                <div className="d-flex flex-row justify-content-between align-items-center card cookie p-3" style={{ pointerEvents: 'auto', backgroundColor: 'rgb(125,217,87)' }}>
                    <div className="d-flex flex-row align-items-center" >
                        <div className="ml-2 mr-2" style={{ textAlign:'justify' }}><span style={{ color:'white' }}> Napište nám do poznámky při registraci kód <strong>JEDNIČKA</strong> a získejte <strong>30% slevu</strong> na první kurz! </span></div>
                    </div>
		    <div></div>
		
            <div style={{paddingLeft:'20px'}}><button data-bs-toggle="modal" href="#portfolioModalVA" className="btn btn-primary" onClick={this.handleClick} type="button" >Registrujte se zde!</button></div>
                  </div>                 
            </div>
	        <div className="col-md-3" >
                </div>
        </div>
    </div>
  )}

  removeCookiePopup() {
    return (
	null
  )}


  render(){
	{/*return(<div>{ localStorage.getItem('action30perc') ? this.removeCookiePopup() : this.showCookiePopup() }</div>)*/}
	return(<div>{this.showCookiePopup()}</div>)
}
}

export default CookieConsentElement_;