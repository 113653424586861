import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import VA_1 from './VA/VA_1';
import VA_2 from './VA/VA_2';

import CookieConsentElement from './CookieConsent';
import CookieConsentElement_ from './Akce';


import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
    <App />
  //</React.StrictMode>
);

const root2 = ReactDOM.createRoot(document.getElementById('root2'));
root2.render(
  <React.StrictMode>
    <CookieConsentElement />
  </React.StrictMode>
);

const root3 = ReactDOM.createRoot(document.getElementById('root3'));
root3.render(
  <React.StrictMode>
    <VA_1 />
  </React.StrictMode>
);

const root4 = ReactDOM.createRoot(document.getElementById('root4'));
root4.render(
  <React.StrictMode>
    <VA_2 />
  </React.StrictMode>
);

{/*const root_akce = ReactDOM.createRoot(document.getElementById('root_akce'));
root_akce.render(
  <React.StrictMode>
    <CookieConsentElement_ />
  </React.StrictMode>
);*/}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
