import '../App.css';
import axios from "axios";
import { Form, Button} from 'semantic-ui-react';
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const selectOptions = [
  { value: "Není momentálně k dispozici", label: "Není momentálně k dispozici" }
];

const selectOptions2 = [
{value: "Facebook", label:"Facebook"},
{value: "Instagram", label:"Instagram"},
{value: "Letáky", label:"Letáky"},
{value: "Doporučeno známými", label:"Doporučeno známými"}
];

export default function VA_1 () {
 const form = useRef();

 const sendEmail = (e) => {
   //e.preventDefault(); // prevents the page from reloading when you hit “Send”
   
   emailjs.sendForm('service_hd692fh', 'template_8aokhwd', form.current, 'hoQqE11c7WXYIZBsZ')
     .then((result) => { console.log(form.current);
  alert('Děkujeme! Ozveme se Vám nejpozději do 24 hodin! Kromě složky Přijatých zpráv zkontrolujte, prosím, také i svou složku Nevyžádaná pošta a Spam.'); 
  reset({name:"", choice:"", datofb:"",email:"", phone:"", program:"", checkBox:"", checkBox2:"", recommend:""});
  emailjs.sendForm('service_yknz7r4', 'template_hgsrx8c', form.current, 'lDf2kqUzxXt-loIXT')
  
 
     }, (error) => {
         alert('Je nám to líto! Něco se pokazilo! Zkuste to, prosím, znovu.');
        
     });
  
 };

const methods = useForm();
    const { register, control, handleSubmit, reset, formState: { errors } } = useForm(
 );
  


    return (
        <div>
            <form ref={form} onSubmit={handleSubmit(sendEmail)}>
                
                
		<div className="form-floating mb-3">
                    
                    <input className="form-control background_ noHover" id="course" readonly="readonly"
                        type="text" placeholder="" 
                       {...register("course", {value:"Víkendová akce - Děti od 6 do 8 let (včetně)"})}
                    />
		    <label >Kurz</label>
		    </div>

<div className="form mb-3">  
<select className="form-select" style={{fontSize:"1.1em"}}  {...register('choice', { required: true})}>
  <option value = "" disabled selected> Místo konání<span className="required">*</span></option>
  <option value="Není momentálně k dispozici">Není momentálně k dispozici</option>

</select>
</div>                
  {errors.choice && <p style={{color:"red"}}>Pole Místo konání je požadováno</p>} 
                


		    <div className="form-floating mb-3">
                    
                    <input className="form-control" id="name"
                        type="text" placeholder=""
                        {...register("name", { required: true})}
                    />
		    <label >Jméno a příjmení rodiče<span className="required">*</span></label>
		    </div>
                {errors.name && <p style={{color:"red"}}>Pole Jméno a příjmení rodiče je požadováno</p>}

		        
        	 <div className="form-floating mb-3">                    
                    <input
                        className="form-control" id="datofb" type="date" placeholder=""
                        {...register("datofb",{
                                required: true
                            })}
                    />
		    <label>Datum narození dítěte<span className="required"></span></label>
		    </div>

		{errors.datofb && <p style={{color:"red"}}>Pole Datum narození dítěte je požadováno</p>}

		<div className="form-floating mb-3">                    
                    <input
                        className="form-control" id="email" type="text" placeholder=""
                        {...register("email",{
                                required: true,
                                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            })}
                    />
		    <label>E-mail<span className="required"></span></label>
		    </div>
		{errors.email && <p style={{color:"red"}}>Pole E-mail je požadováno</p>}

		    <div className="form-floating mb-3">                    
                    <input
                        className="form-control" id="phone" type="phone" placeholder=""
                        {...register("phone",{
                                required: true})}
                    />
		    <label>Telefon</label>
		    </div>
		{errors.phone && <p style={{color:"red"}}>Pole Telefon je požadováno</p>} 


		    <div className="form-floating mb-3">                    
                    <textarea className="form-control" id="message" type="text" placeholder="" style={{height: "10rem"}}
                        {...register("program")}
                    />
		    <label>Jméno dítěte, těžkosti nebo alergie...</label>
		    </div>

<div className="form mb-3">  
<select className="form-select" style={{fontSize:"1.1em"}}  {...register('recommend')}>
  <option value = "" disabled selected> Znáte nás z<span className="required">*</span></option>
  <option value="Facebook">Facebook</option>
  <option value="Instagram">Instagram</option>
  <option value="Letáky">Letáky</option>
  <option value="Doporučeno známými">Doporučeno známými</option>
</select>
</div> 

<div className="form-floating mb-3"> 
	<p><input
              type="checkbox"
              name="selectCheckbox"
              id="selectCheckbox"
              {...register('checkBox', { required: true})}
              
            /> <label style={{color:"black"}}>Souhlasím s</label> <label><a data-bs-toggle="modal" href="#portfolioModalGDPR">Zásady ochrany osobních údajů</a><span className="required" style={{color:"black"}}>*</span></label></p>

</div>
  {errors.checkBox && <p style={{color:"red"}}>Pole Souhlasím s Zásady ochrany osobních údajů je požadováno</p>}  

<div className="form-floating mb-3"> 
	<p><input
              type="checkbox"
              name="selectCheckbox"
              id="selectCheckbox"
              {...register('checkBox2', { required: true})}
              
            /> <label style={{color:"black"}}>Souhlasím s</label> <label><a data-bs-toggle="modal" href="#portfolioModalObchodniPodminky">Všeobecné obchodní podmínky </a><span className="required" style={{color:"black"}}>*</span></label></p>

</div>
  {errors.checkBox2 && <p style={{color:"red"}}>Pole Souhlasím s Všeobecné obchodní podmínky je požadováno</p>}  

{/*<div className="form-floating mb-3"> 
<input type="hidden" readonly="readonly" {...register("action", { value: "akce 1" })} />
</div>                
<div className="form-floating mb-3"> 
<input type="hidden" readonly="readonly" {...register("time", { value: "9:00 - 13:00" })} />
</div> 
<div className="form-floating mb-3"> 
<input type="hidden" readonly="readonly" {...register("price", { value: "1 210 Kč " })} />
</div> */}


                <Button className="btn btn-primary btn-xl justify-center" type="submit" disabled>Odeslat</Button>
                
            </form>
        </div>
    )
}
